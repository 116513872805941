import { Button, DatePicker, Modal, Popover, Table } from "antd";
import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { FinanceEntryType } from "../typeDefs";
import { Finances } from "./Finances";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { PrintFinances } from "./PrintFinances";

export const FinanceReports = () => {
  const [data, setData] = useState<Array<FinanceEntryType>>([]);
  const [summaryData, setSummaryData] = useState<Array<any>>([]);
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState<Array<number>>([
    new Date(`${new Date().getFullYear()}-04-01`).getTime(),
    new Date().getTime(),
  ]);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "finances"), (snapshot) => {
      setData([]);
      snapshot.forEach((doc) => {
        setData((prev) => [
          ...prev,
          { ...doc.data(), id: doc.id } as FinanceEntryType,
        ]);
      });
    });

    return unsub;
  }, []);

  useEffect(() => {
    setSummaryData([]);
    let gstOut = 0;
    let gstIn = 0;
    let gstBalance = 0;
    let expenses = 0;
    let revenue = 0;
    let purchases = 0; // gst balance
    data
      .filter((item) => item.date >= dateRange[0] && item.date < dateRange[1])
      .filter((item) => item.category === "Revenue")
      .filter((item) => item.date >= new Date("2024-05-01").getTime())
      .forEach((item) => {
        gstOut = gstOut + item.amount * 0.15;
      });

    data
      .filter((item) => item.date >= dateRange[0] && item.date < dateRange[1])
      .filter((item) => item.category === "Assets")
      .forEach((item) => {
        gstIn = gstIn + item.amount * 0.15;
      });

    data
      .filter((item) => item.date >= dateRange[0] && item.date < dateRange[1])
      .filter((item) => item.category === "Expenses")
      .forEach((item) => {
        expenses = expenses + item.amount;
      });

    data
      .filter((item) => item.date >= dateRange[0] && item.date < dateRange[1])
      .filter((item) => item.category === "Revenue")
      .forEach((item) => {
        revenue = revenue + item.amount;
      });

    data
      .filter((item) => item.date >= dateRange[0] && item.date < dateRange[1])
      .filter((item) => item.category === "Assets")
      .forEach((item) => {
        purchases = purchases + item.amount;
      });

    gstBalance = gstIn - gstOut;

    setSummaryData([
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>Total revenue</div>
            <Popover content="All money entering into the business">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount: `$${revenue.toFixed(2)}`,
      },
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>Total expenses</div>
            <Popover content="Money spent on wages and non-asset purchases">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount: `$(${expenses.toFixed(2)})`,
      },
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>Total asset purchases</div>
            <Popover content="Money spent on purchasing assets">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount: `$(${purchases.toFixed(2)})`,
      },
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>Total purchases</div>
            <Popover content="Money spent on purchasing assets and expenses">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount: `$(${(purchases + expenses).toFixed(2)})`,
      },

      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>GST to claim</div>
            <Popover content="GST on qualifing purchases">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount: `$${gstIn.toFixed(2)}`,
      },
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>GST owing</div>
            <Popover content="GST on qualifing invoices sent">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount: `$(${gstOut.toFixed(2)})`,
      },
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>GST balance</div>
            <Popover content="GST to claim - GST owing">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount:
          gstBalance < 0
            ? `$(${gstBalance.toFixed(2)})`
            : `$${gstBalance.toFixed(2)}`,
      },
    ]);
  }, [data, dateRange]);

  const summaryColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "subCategory",
      key: "subCategory",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
    },
    {
      title: "GST Number",
      dataIndex: "gstNumber",
      key: "gstNumber",
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const assetsColumns = [
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "subCategory",
      key: "subCategory",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
    },
    {
      title: "GST Number",
      dataIndex: "gstNumber",
      key: "gstNumber",
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  return (
    <div
      style={{
        height: "100%",
        width: "100vw",
        position: "fixed",
        zIndex: "15",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "rgb(230,230,230)",
        color: "rgb(200,200,200)",
      }}
    >
      <div
        style={{
          width: "100vw",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "5rem",
          overflowY: "scroll",
        }}
      >
        <h1
          style={{
            padding: "1rem",
            color: "rgb(20,20,20)",
            width: "92%",
            textAlign: "left",
            fontFamily: "Oxanium",
            fontSize: "1.5rem",
          }}
        >
          Finance Reports
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "90%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <RangePicker
              onChange={(e) =>
                e &&
                setDateRange([
                  e[0]?.toDate().getTime() as number,
                  e[1]?.toDate().getTime() as number,
                ])
              }
            />{" "}
            <PrintFinances data={data} dates={dateRange} />
          </div>{" "}
          <Button
            onClick={() => (isModal ? setIsModal(false) : setIsModal(true))}
          >
            New Entry
          </Button>
        </div>
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  //padding: "10px",
                  textAlign: "left",
                  //width: "100%",
                  color: "dodgerblue",
                  fontSize: "1.5rem",
                  marginRight: "1rem",
                }}
              >
                {`${new Date(dateRange[0]).toLocaleDateString(
                  "en-GB"
                )} - ${new Date(dateRange[1]).toLocaleDateString(
                  "en-GB"
                )} Revenue`}
              </div>
              <Popover content="Total money flow into the business">
                <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
              </Popover>
            </div>
          )}
          columns={columns}
          dataSource={data
            .filter(
              (item) => item.date >= dateRange[0] && item.date < dateRange[1]
            )
            .filter((item) => item.category === "Revenue")
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map((item) => {
              return {
                ...item,
                date: new Date(item.date).toLocaleDateString("en-GB"),
                amount: `$(${item.amount.toFixed(2)})`,
                amountNumber: item.amount,
                gst:
                  item.gstNumber && item.gstNumber?.length > 5
                    ? `$${(item.amount * 0.15).toFixed(2)}`
                    : null,
                key: item.id,
                edit: (
                  <Button
                    type="text"
                    icon={<EditOutlined style={{ color: "dodgerblue" }} />}
                  />
                ),
              };
            })}
          size="small"
          style={{ width: "90%" }}
          pagination={false}
          footer={(pageData) => {
            let total = 0;

            pageData.forEach((item) => {
              total += item.amountNumber;
            });
            return (
              <div
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "right",
                  width: "100%",
                }}
              >{`Total: ${total.toFixed(2)}`}</div>
            );
          }}
        />
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  //padding: "10px",
                  textAlign: "left",
                  //width: "100%",
                  color: "dodgerblue",
                  fontSize: "1.5rem",
                  marginRight: "1rem",
                }}
              >
                {`${new Date(dateRange[0]).toLocaleDateString(
                  "en-GB"
                )} - ${new Date(dateRange[1]).toLocaleDateString(
                  "en-GB"
                )} Assets`}
              </div>
              <Popover content="Assets held in the business based on purchases made by the business">
                <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
              </Popover>
            </div>
          )}
          columns={columns}
          dataSource={data
            .filter(
              (item) => item.date >= dateRange[0] && item.date < dateRange[1]
            )
            .filter((item) => item.category === "Assets")
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map((item) => {
              return {
                ...item,
                date: new Date(item.date).toLocaleDateString("en-GB"),
                amount: `$${item.amount.toFixed(2)}`,
                amountNumber: item.amount,
                gst:
                  item.gstNumber && item.gstNumber?.length > 5
                    ? `$${(item.amount * 0.15).toFixed(2)}`
                    : null,
                key: item.id,
              };
            })}
          size="small"
          style={{ width: "90%" }}
          pagination={false}
          footer={(pageData) => {
            let total = 0;

            pageData.forEach((item) => {
              total += item.amountNumber;
            });
            return (
              <div
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "right",
                  width: "100%",
                }}
              >{`Total: ${total.toFixed(2)}`}</div>
            );
          }}
        />
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  //padding: "10px",
                  textAlign: "left",
                  //width: "100%",
                  color: "dodgerblue",
                  fontSize: "1.5rem",
                  marginRight: "1rem",
                }}
              >
                {`${new Date(dateRange[0]).toLocaleDateString(
                  "en-GB"
                )} - ${new Date(dateRange[1]).toLocaleDateString(
                  "en-GB"
                )} Expenses`}
              </div>
              <Popover content="Money flowing out of the business for services, subscriptions, salary and other expenses">
                <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
              </Popover>
            </div>
          )}
          columns={columns}
          dataSource={data
            .filter(
              (item) => item.date >= dateRange[0] && item.date < dateRange[1]
            )
            .filter((item) => item.category === "Expenses")
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map((item) => {
              return {
                ...item,
                date: new Date(item.date).toLocaleDateString("en-GB"),
                amount: `$(${item.amount.toFixed(2)})`,
                amountNumber: item.amount,
                gst:
                  item.gstNumber && item.gstNumber?.length > 5
                    ? `$${(item.amount * 0.15).toFixed(2)}`
                    : null,
                key: item.id,
              };
            })}
          size="small"
          style={{ width: "90%" }}
          pagination={false}
          footer={(pageData) => {
            let total = 0;

            pageData.forEach((item) => {
              total += item.amountNumber;
            });
            return (
              <div
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "right",
                  width: "100%",
                }}
              >{`Total: ${total.toFixed(2)}`}</div>
            );
          }}
        />
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  //padding: "10px",
                  textAlign: "left",
                  //width: "100%",
                  color: "dodgerblue",
                  fontSize: "1.5rem",
                  marginRight: "1rem",
                }}
              >
                {`${new Date(dateRange[0]).toLocaleDateString(
                  "en-GB"
                )} - ${new Date(dateRange[1]).toLocaleDateString(
                  "en-GB"
                )} Salaries & Wages`}
              </div>
              <Popover content="Money flowing out of the business to pay for staff wages and shareholder salaries">
                <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
              </Popover>
            </div>
          )}
          columns={columns}
          dataSource={data
            .filter(
              (item) => item.date >= dateRange[0] && item.date < dateRange[1]
            )
            .filter(
              (item) =>
                item.category === "Expenses" && item.description === "Draw"
            )
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map((item) => {
              return {
                ...item,
                date: new Date(item.date).toLocaleDateString("en-GB"),
                amount: `$(${item.amount.toFixed(2)})`,
                amountNumber: item.amount,
                gst:
                  item.gstNumber && item.gstNumber?.length > 5
                    ? `$${(item.amount * 0.15).toFixed(2)}`
                    : null,
                key: item.id,
              };
            })}
          size="small"
          style={{ width: "90%" }}
          pagination={false}
          footer={(pageData) => {
            let total = 0;

            pageData.forEach((item) => {
              total += item.amountNumber;
            });
            return (
              <div
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "right",
                  width: "100%",
                }}
              >{`Total: ${total.toFixed(2)}`}</div>
            );
          }}
        />
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  //padding: "10px",
                  textAlign: "left",
                  //width: "100%",
                  color: "dodgerblue",
                  fontSize: "1.5rem",
                  marginRight: "1rem",
                }}
              >
                {`${new Date(dateRange[0]).toLocaleDateString(
                  "en-GB"
                )} - ${new Date(dateRange[1]).toLocaleDateString(
                  "en-GB"
                )} Summary`}
              </div>
              <Popover content="Table of calculated summary data">
                <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
              </Popover>
            </div>
          )}
          columns={summaryColumns}
          dataSource={summaryData}
          size="small"
          style={{ width: "90%" }}
          pagination={false}
        />
      </div>
      <Modal open={isModal} onCancel={() => setIsModal(false)} footer={null}>
        <Finances />
      </Modal>
    </div>
  );
};
